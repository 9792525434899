import { useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import {FileSearchOutlined, FileDoneOutlined } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SignatureOutlined, EyeOutlined  } from '@ant-design/icons';
import PDFView from './PDF';
import Loader from '../atoms/loader';
import DocumentsHeader from '../molecules/documents-header';
import { useMessages } from '../../context/MessageContext';
import useWindowSize from '../../hooks/useWindowSize.hook';
import { LayoutBreakpoints } from '../../constants/config.contants';

const Documents = ({
    setStateDocumentSelected,
    onChangeCounter = () => {},
}) => {
    const dispatch = useDispatch();
    const { width } = useWindowSize();
    const validateRfc = require('validate-rfc');
    const [status, setStatus] = useState("1");
    const [keyCollapse, setKeyCollapse] = useState('');
    const [initialLoaded, setInitialLoaded] = useState(false);
    const { messages, loading } = useMessages();
    const [isMobile, setIsMobile] = useState(false);
    const [visitedMobile, setVisitedMobile] = useState([]);
    const [currentKey, setCurrentKey] = useState(-1);

    const listLegalDocuments = useSelector((state) => state.LegalDocumentsReducer.listLegalDocuments);
    const fetchingListLegalDocuments = useSelector((state) => state.LegalDocumentsReducer.fetchingListLegalDocuments);
    const fetchingSignDocument = useSelector((state) => state.LegalDocumentsReducer.fetchingSignDocument);

    const identityId = useSelector((state) => state.LegalDocumentsReducer.identityId);
    const accessToken = useSelector((state) => state.LegalDocumentsReducer.accessToken);
    const documentTypeOwner = useSelector((state) => state.LegalDocumentsReducer.documentTypeOwner);

    const initialValues = {
        identityId: identityId,
        accessToken: accessToken,
        documentTypeOwner: documentTypeOwner
    }

    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        if (listLegalDocuments && listLegalDocuments.length > 0) {
            if (initialLoaded || listLegalDocuments.length == 1) {
                var indexToSign = listLegalDocuments.findIndex(doc => !doc.acceptance);
                if (indexToSign != -1) {
                    onCollapse([`${indexToSign}`]);
                }
            } else {
                handleSetDocumentSelected(listLegalDocuments[0]);
            }
            setInitialLoaded(true);
        }
    }, [listLegalDocuments]);

    useEffect(() => {
        if(!listLegalDocuments !== undefined && identityId !== undefined && accessToken !== undefined){
            dispatch({type: 'GET_LIST_DOCUMENTS_REQUEST', status: status, formInitialData: initialValues});
        }
    }, [identityId]);

    useEffect(() => {
        if (width > 0) {
            setIsMobile(width < LayoutBreakpoints.SMALL);
        }
    }, [width]);

    useEffect(() => {
        if (isMobile) {
            onCollapse(['0']);
        }
    }, [isMobile]);

    const scrollHandler = (event, elementSelected) => 
    {
        let targetElement = event.target;
        if (elementSelected.statusID === 0 || elementSelected.statusID === 1) {
            if (!elementSelected.booleanSigned && targetElement.scrollTop >0) {
                if (targetElement.offsetHeight + targetElement.scrollTop >= targetElement.scrollHeight -1) {
                    activateDocumentSelected(elementSelected);
                }
            }
        }
    }

    const onCollapse = (e) => {
        setKeyCollapse(`${e}`);
        let elementSelected = {};
        if(e.length){
            setCurrentKey(parseInt(e[0]));
            elementSelected = listLegalDocuments[parseInt(e[0])];

            if (!isMobile) {
                const wrappedEventHandler = (event) => scrollHandler(event, elementSelected);
                let allElements = document.getElementsByClassName("legal-documents-pdf");
                for (let element of allElements) {
                    element.removeEventListener('scroll', wrappedEventHandler);
                }
                let collapsableElement = document.getElementsByClassName("legal-documents-pdf")[parseInt(e[0])];
                collapsableElement.addEventListener('scroll', wrappedEventHandler);
            }                
            handleSetDocumentSelected(elementSelected);
            onChangeCounter(parseInt(e) + 1);
        }
    };

    const onValidateLinkVisited = (elementSelected, indexDoc) => {
        if (elementSelected.statusID === 0 || elementSelected.statusID === 1) {
            if (!elementSelected.booleanSigned && visitedMobile.length > 0) {
                if (visitedMobile.includes(indexDoc)) {
                    activateDocumentSelected(elementSelected);
                }
            }
        }
    }

    const handleChangeMobile = (item) => {
        let elementSelected = {};
        if(item >= 0){
            setKeyCollapse([`${item}`]);
            setCurrentKey(item);
            elementSelected = listLegalDocuments[item];
            handleSetDocumentSelected(elementSelected);
            onValidateLinkVisited(elementSelected, item);
            onChangeCounter(parseInt(item) + 1);
        }
    };

    const handleSetDocumentSelected = (elementSelected) => {
        dispatch({type: 'SET_VALUE', payload: {field: 'showGeneralCloseSession', value: false}});
        dispatch({type: 'DOCUMENT_SELECTED', documentSelected: elementSelected, fetchingDocumentSelected: false });
        setStateDocumentSelected(false);
    };

    const activateDocumentSelected = (elementSelected) => {
        elementSelected.booleanSigned = true;
        dispatch({type: 'SET_VALUE', payload: {field: 'documentSelected', value: elementSelected}});
        setStateDocumentSelected(true);
    };

    const handleViewPdfMobile = (index) => {
        let elementSelected = {};
        if (index >= 0) {
            if(!visitedMobile.includes(index)) {
                setVisitedMobile([...visitedMobile, index]);
            }
            setCurrentKey(index);
            elementSelected = listLegalDocuments[index];
            handleSetDocumentSelected(elementSelected);
            activateDocumentSelected(elementSelected);
            if (elementSelected.storageUrl) {
                window.open(elementSelected?.storageUrl,'_blank');
            }
        }
    };

    if (loading){
        return <></>
    }

    return (
        <div className="legal-docs-detail">
            <DocumentsHeader logoAppSrc={'/logo.svg'} title={messages.DOCUMENT_TITLE_UPDATE.toUpperCase()} />
            <div className="legal-docs-detail__body">
                {/* <Row justify='space-between'>
                    <Typography.Title level={5} style={{ color:'#5E5E5E'}}>{'Actualizamos nuestros documentos'.toUpperCase()} <Button type="link" icon={<QuestionCircleFilled/>} /></Typography.Title>
                    <Col style={{paddingTop: '1.8em'}}>
                        <Select
                            defaultValue={status}
                            variant="filled"
                            style={{  width: 120 }}
                            onChange={handleChangeStatus}
                            options={[ { value: '0', label: 'Todos', }, { value: '1', label: 'Pendiente', },  { value: '2', label: 'Aceptado',  },  { value: '3', label: 'Rechazado', }, ]}
                        />
                    </Col>
                </Row> */}
                {!fetchingSignDocument && (
                <div className="legal-docs-detail__advice">Debes leer los documentos para continuar</div>
                )}
                { !fetchingListLegalDocuments ? (
                    <>
                        {isMobile && (
                            <div className="legal-docs-detail__list">
                                { listLegalDocuments.map((document, index) => (
                                    <div key={`legal-docs-detail__list__item ${index}`} className={`legal-docs-detail__list__item ${currentKey === index ? 'legal-docs-detail__list__item--active':''}`}>
                                        <div className="legal-docs-detail__list__control" onClick={()=>handleChangeMobile(index)}>
                                            <div className="legal-docs-detail__list__name">
                                                <span className={`legal-docs-detail__number ${document.sign ? 'legal-docs-detail__number--active':''}`}>{index + 1}</span>
                                                {document.label} 
                                            </div>
                                        </div>
                                        {document.sign ? (
                                            <CheckCircleFilled onClick={() => handleViewPdfMobile(index)} style={{ fontSize: '24px', color: 'var(--theme-default-color)' }} />
                                        ) : (
                                            <EyeOutlined onClick={() => handleViewPdfMobile(index)} className="base-icon--default" />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        { listLegalDocuments && 
                            <Collapse
                                activeKey={[keyCollapse]}
                                className={`legal-docs-detail__collapse ${isMobile ? 'hidden' : ''}`}
                                onChange={(element) => onCollapse(element)}
                                bordered={false}
                                accordion
                                expandIcon={({ isActive }) => !isActive ? <SignatureOutlined className="base-icon--default" /> : <EyeOutlined className="base-icon--active" />}
                            >
                                { listLegalDocuments.map((document, index) => (
                                    <Collapse.Panel 
                                        key={index} forceRender
                                        collapsible={listLegalDocuments[0]?.noData ? 'disabled' : 'header'} 
                                        header={
                                            <>
                                                <div className="legal-docs-detail__title">
                                                    <span className={`legal-docs-detail__number ${index === parseInt(keyCollapse) ? 'legal-docs-detail__number--active':''}`}>{index + 1}</span>
                                                    {document.label} 
                                                </div>
                                                {document.sign ? (
                                                    <CheckCircleFilled onClick={() => onCollapse(index === parseInt(keyCollapse) ? '' : index)} style={{ fontSize: '24px', color: 'var(--theme-default-color)' }} />
                                                ) : (
                                                    <CheckCircleFilled onClick={() => onCollapse(index === parseInt(keyCollapse) ? '' : index)} style={{ fontSize: '24px', color: 'var(--theme-dark-color)' }} />
                                                )}
                                            </>
                                        }>
                                        <div className='legal-documents-pdf'>
                                            <PDFView/>
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        }
                    </>
                ) : (
                    <div className="legal-docs-detail__loader">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
}
export default Documents;
