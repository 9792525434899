export const FlowOptions = {
  VALIDATE_DOCUMENTS_TO_SIGN: 'ValidateDocumentsToSign',
  LOGIN: 'Login',
  EXTERNAL_COMPANY: 'ExternalCompany',
  ACTIVATION: 'Activation'
}

export const EventTypes = {
  CLOSE_IFRAME: 'closeIframe',
  SHOW_PORTAL: 'showPortal',
  REDIRECT: 'redirect',
  CLOSE_SESSION: 'closeSession',
}

export const LayoutBreakpoints = {
  SMALL: 768,
}
