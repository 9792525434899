import { useEffect, useState } from "react";

function useWindowSize () {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    heigh: 0,
  });

  useEffect(()=> {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          heigh: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowSize;
}

export default useWindowSize;