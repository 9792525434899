import axios from 'axios';
// import baseUrl from '../endpoints';
import {apiUrl, subscriptionKey} from '../endpoints.js';

const endpoint = apiUrl;
const subsKey = subscriptionKey;

    export function listLegalDocumentsCall(
        documentTypeOwner, 
        status, 
        identityId, 
        roleId, 
        orderBy, 
        sortDirection, 
        rfc, 
        serviceId, 
        authorization, 
        transportKey,
        licenseId
    ) {

        let headers = {
            'Content-Type': 'application/json', 
            'Authorization': authorization, 
            'identityId': identityId,
            'Ocp-Apim-Subscription-Key': subsKey,
            'transportKey': transportKey,
            'licenseId': licenseId
        };

        if (rfc)
            headers['rfc'] = rfc;
        if (serviceId)
            headers['serviceId'] = serviceId;

        return axios({
            method: 'get',
            url: endpoint + 'api/v1/legalDocuments?version=1&' + documentTypeOwner + status + roleId  + orderBy + sortDirection,
            headers: headers
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
    }

    export function sendDocumentSignedCall(data, headers) {
        headers['Ocp-Apim-Subscription-Key'] = subsKey;
        return axios({
            method: 'post',
            url: endpoint + 'api/v1/legalDocuments/sign-document',
            headers: headers,
            data: data
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
    }
    